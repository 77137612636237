import React from 'react'
import ReactMarkdown from 'react-markdown'

import styled from 'styled-components'
import Header from '../../../components/header/header'
import Desc from '../../../components/desc/desc'
import Line from '../../../components/line/line'
import ContentWrap from '../../../components/contentWrap/contentWrap'

import Fade from 'react-reveal/Fade'
// import Bounce from 'react-reveal/Bounce'

const imgMin = '120px'
const imgBig = '150px'

const Img = styled.div`
  width: ${props => imgMin};
  height: ${props => imgMin};
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;

  &::before {
    content: '';
    width: ${props => imgMin};
    height: ${props => imgMin};
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background: ${({ theme }) =>
      'linear-gradient(90deg, ' +
      theme.colorYellow +
      ', ' +
      theme.colorOrange +
      ')'};
    transform: translate(10px, 10px);
    border-radius: 10px;
  }

  @media (min-width: 1400px) {
    width: ${props => imgBig};
    height: ${props => imgBig};
    &::before {
      width: ${props => imgBig};
      height: ${props => imgBig};
    }
  }
`

const ImgWrapper = styled.li`
  margin: 10px 5px;
  position: relative;
`

const ListImg = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 0;
  @media (min-width: 1400px) {
    justify-content: space-between;
  }
`

const ListText = styled.ul`
  display: grid;
  row-gap: 25px;
  justify-content: flex-start;
  align-items: start;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
`
const ListTextElement = styled.li`
  display: grid;
  row-gap: 15px;
  justify-content: flex-start;
  align-items: start;
  margin: 10px 0;
`

const StyledContentWrap = styled(ContentWrap)`
  display: grid;
  /* row-gap: 30px; */
  justify-content: center;
  align-items: center;
`

const StyledLine = styled(Line)`
  margin: 30px 0 15px;
  height: 4px;
  border-radius: 2px;

  @media (min-width: 1200px) {
    margin: 60px auto 40px;
    width: 70%;
  }
`
const StyledDesc = styled(Desc)`
  @media (min-width: 1200px) {
    margin: 10px 0;
  }
`

// const StyledHeader = styled(Header)`
//   text-align: center;
//   @media (min-width: 768px) {
//     text-align: left;
//   }
// `

const CategorySection = props => {
  const textData = [
    { head: 'o czym należy pamiętać?', desc: props.obj.liRemember },
    { head: 'dodatkowe godziny', desc: props.obj.liHour },
  ]

  return (
    <Fade>
      {/* // <Bounce ssrFadeout onReveal={() => alert('pokazane')}> */}
      <>
        <StyledContentWrap as={'main'}>
          <Header h2 firstLetter align="left" noMargin>
            <ReactMarkdown
              disallowedTypes={['paragraph']}
              unwrapDisallowed
              children={props.obj.categoryTitle}
            />
          </Header>
          <Desc align="left">
            {/* <ReactMarkdown
              disallowedTypes={['paragraph']}
              unwrapDisallowed
              children={props.obj.desc}
            /> */}
            <div
              dangerouslySetInnerHTML={{ __html: props.obj.desc }}
              className="cms-text"
            />
          </Desc>

          <ListImg>
            {props.obj.images.map(image => (
              <ImgWrapper>
                <Img src={image.url} alt={'ilustracja pojazdu'} />
              </ImgWrapper>
            ))}
          </ListImg>

          <ListText>
            {textData.map(el => (
              <ListTextElement>
                <Header yellowRect as={'h3'} align="left">
                  <ReactMarkdown
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                    children={el.head}
                  />
                </Header>
                <StyledDesc align="left">
                  {/* <ReactMarkdown
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                    children={el.desc}
                  /> */}
                  <div
                    dangerouslySetInnerHTML={{ __html: el.desc }}
                    className="cms-text"
                  />
                </StyledDesc>
              </ListTextElement>
            ))}
          </ListText>
          {!props.isLast && <StyledLine />}
        </StyledContentWrap>
      </>
      {/* </Bounce> */}
    </Fade>
  )
}

export default CategorySection
