import React, { useState, useEffect } from 'react'
import axios from '../utils/axios'
import CategorySection from '../sections/courses/categorySection/categorySection'
import Banner from '../partials/banner/banner'
import Layout from '../layout/layout'
import Loader from '../partials/loader/loader'

import contactQuery from '../utils/contactQuery'
import Footer from '../partials/footer/footer'

const About = () => {
  const [respData, setRespData] = useState({})
  const [isDataFetched, setIsDataFetched] = useState(false)

  useEffect(() => {
    const fc = async () => {
      const response = await axios({
        data: JSON.stringify({
          query: `{
          course{
            imgHeader{
              url
            }
          }
          allCourseCategories{
            categoryTitle
            desc
            images{
              url
            }
            liRemember
            liHour
            order
          }
        ${contactQuery}

        }`,
        }),
      })

      response.data.data.allCourseCategories.sort((a, b) =>
        a.order * 1 > b.order * 1 ? 1 : -1
      )
      // const categories = [...response.data.data.allCourseCategories]
      // categories.sort((a, b) => a.order * 1 > b.order * 1)
      // alert(JSON.stringify(response.data.data.allCourseCategories))
      // alert(
      //   `${response.data.data.allCourseCategories[0].order},
      //   ${response.data.data.allCourseCategories[1].order},
      //   ${response.data.data.allCourseCategories[2].order}
      //   `
      // )
      // alert(
      //   `${categories[0].order},
      //   ${categories[1].order},
      //   ${categories[2].order}
      //   `
      // )
      // alert(response.data.data.allCourseCategories[1].order)
      // console.log(response.data.data)
      setRespData(response.data.data)
      setIsDataFetched(true)
    }
    fc()
  }, [])

  return (
    <>
      {<Loader isDataLoaded={isDataFetched} />}

      <Layout title="Kursy">
        {/* {<Loader isDataLoaded={isDataFetched} />} */}

        {isDataFetched && (
          <>
            <Banner img={respData.course.imgHeader.url}>kursy</Banner>
            <ul>
              {respData.allCourseCategories.map((obj, i) => (
                <li>
                  <CategorySection
                    obj={obj}
                    isLast={i + 1 == respData.allCourseCategories.length}
                  />
                </li>
              ))}
            </ul>
            <Footer data={respData.contact} />
          </>
        )}
      </Layout>
    </>
  )
}

export default About
