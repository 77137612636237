import styled from 'styled-components'

const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colorYellow};
  /* background-color: ${({ theme }) => theme.colorOrange}; */
  margin: 20px 0;
  border-radius: 1px;
`
export default Line
